.login {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: calc(100vh - 170px);
  background-image: url("../images/earth.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.account-login {
  width: 500px;
  border: 1px solid black;
  padding: 10px 15px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
}

.account-login dl {
  margin-bottom: 0;
}

.account-login dt {
  padding: 5px 5px 5px 0;
}

.account-login dd {
  padding: 0 0 0 10px;
  margin-bottom: 4px;
  overflow-wrap: break-word;
}

.navbar-right {
  display: flex;
  gap: 5px;
}
