.cesium-checkbox {
  border: solid 1px #444;
  background-color: #303336;
  color:#edfffe;
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 3px 12px;
  font-weight: lighter;
}

.cesium-notifyBox {
  border: solid 1px #444;
  background-color: #303336;
  display: inline;
  float:right;
  color:#edfffe;
  border-radius: 5px;
  box-sizing: border-box;
  font-weight: lighter;
  bottom:10px;
  margin-left: 90px;
  margin-top:10px;
  margin-bottom: 1px;
  padding: 3px;
  font-size: 13px;
}

.Cesium-popBox {
  position: absolute;
  top: -230px;
  left: 5px;
  height: 220px;
  background-color: rgba(38, 38, 38, 0.75);
  border: solid #ddd 1px;
  width: 300px;
  padding: 10px;
  border-radius: 10px;
  font-size: small;
  visibility: hidden;
  transform: scale(0.01);
  transform-origin: 0px 200px;
  transition: visibility 0s 0.25s, transform 0.25s ease-in;
}

.cesium-navigation-help {
  z-index: 20;
}

.Cesium-popBox-out {
  visibility: visible;
  transform: scale(1);
  transition: transform 0.25s ease-out;
}

.cesium-visit-button {
  margin: 5px;
}

#viewerChange>div>table {
  margin: auto;
}

#viewerChange>div>table>tbody>tr>td {
  padding: 10px;
}

.Cesium-label {
  color: #ddd;
  width: 80px;
}

.Cesium-input {
  border: solid 1px #444;
  background-color: rgba(40, 40, 40, 0.7);
  color: #ddd;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  border-radius: 0;
  box-sizing: border-box;
}

.Cesium-refresh {
  position: absolute;
  right: 5px;
  top: 50px;
}

.Cesium__norecord {
  padding: 10px;
  font-size: 20px;
}

#maxPointBox{
  font-size: 20px;
  color:white;
  display: inline-block;
  border: solid 1px #444;
  background-color: rgba(40, 40, 40, 0.7);
}

#container {
  height: 100px;
  overflow: auto; 
  color:white;
  font-size:15px;
  font-weight: bold;
  margin-bottom: 10px;
}

.list-item {
  font-weight:lighter;
  overflow: hidden; 
  text-overflow: ellipsis;
}

.locationNameButton {
  background-color: #303336;
  color:white;
  border:none;
}

.deleteButton {
  float: right;
  height: 20px;
  padding: 0;
}