html,
body {
  height: 100%;
  box-sizing: border-box;
}

a {
  cursor: pointer;
}

.container {
  padding: 15px;
  min-height: calc(100vh - 170px);
}

.hierarchy-facet {
  overflow-y: auto;
}
.hierarchy-facet h5,h6 {
  display: inline;
}

.switch {
  
  float:right;
  margin-left: auto; 
  margin-right: 0;
}

.list-facet ul {
  overflow-y: auto;
}

.list-facet__custom--height {
  max-height: 300px;
  overflow-y: auto;
}

.list-facet ul li {
  cursor: pointer
}

.list-facet ul li:hover {
  text-decoration: underline;
}

.list-facet__custom input {
  margin-top: 20px;
}

.list-facet__custom ul li:hover {
  text-decoration: none !important;
}

.list-facet__custom ul li {
  padding-top: 5px;
  vertical-align: bottom;
}

.list-facet__custom .facet-item-amount {
  color: #AAAAAA;
}

.list-facet__custom--item:hover {
  text-decoration: underline;
}

.facet-range-slider {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  user-drag: none;
  cursor: pointer;
  width: 100%;
  stroke: #f1ebe6;
  fill: #f1ebe6;
}

.facet-range-slider .range-line {
  stroke-width: 8;
}

.facet-range-slider .range-line circle {
  stroke-width: 0;
}

.facet-range-slider .range-line circle.hovering,
.facet-range-slider .range-line circle:hover {
  fill: #bda47e;
}

.facet-range-slider .range-line path.hovering,
.facet-range-slider .range-line path:hover {
  stroke: #bda47e;
}

.current-query label,
.solr-search-results ul label {
  display: inline-block;
  margin: 0 20px 0 0;
  width: 180px;
  color: #666;
  overflow-y: scroll;
  white-space: nowrap;
  vertical-align: bottom;
}

.solr-search-results ul label::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  height: 0;
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

.solr-search-results ul li ul {
  list-style: none;
  padding: 0;
}

.solr-search-results ul li ul li{
  display: table-row;
}

.solr-search-results ul li ul li label{
  display: table-cell;
  width: 350px;
  vertical-align:top;
}

svg.search-icon {
  stroke: #595959;
  fill: #595959;
}

.current-query .label {
  display: inline-block;
  padding: 4px;
  cursor: pointer;
  margin-left: 4px;
}

.current-query .label:hover a {
  color: #d08989;
}

.current-query .label a {
  color: white;
  margin-left: 4px;
}

.range-facet header h5 {
  max-width: calc(100% - 75px)
}

.facet-item-amount {
  display: inline-block;
  float: right;
  color: #aaa;
}

.list-facet>.list-group {
  box-shadow: none;
}

.list-facet>.list-group>.list-group-item {
  border: none;
}

.list-facet>input {
  width: calc(100% - 125px)
}

.list-group-item div form label {
  display: inline-block;
  margin: 0 20px 0 0;
  width: 108px;
  color: #666;
  overflow-y: auto;
  white-space: nowrap;
  vertical-align: bottom;
}

input[type=number] {
  width: 164px;
}

.popBox {
  position: absolute;
  background-color: rgb(255, 255, 255);
  border: solid #ddd 1px;
  width: 400px;
  z-index: 100;
  padding: 10px 0px 10px 0px;
  border-radius: 10px;
  font-size: small;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
}

.recordTable {
  overflow: scroll;
}


.paginationBox {
  position: relative;
}

.swithButtonBox {
  position: absolute;
  top: -30px;
  bottom: 0;
  height: 30px;
}

.margin-right-xs {
  margin-right: 0.25em;
}

table {
  border-spacing: 0;
  border: 1px solid #ddd;
  color: #666666;
}

th,
td {
  margin: 0;
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.field_select_group {
  position: absolute;
  top: -30px;
  right: 10px;
  color: #666666;
}

.fieldsIcon {
  font-size: 25px;
}

.glyphicon:hover {
  color: rgb(155, 155, 150);
  cursor: pointer;
}

.popBoxField {
  position: absolute;
  right: 0;
  width: fit-content;
  background-color: rgb(255, 255, 255);
  border: solid #ddd 1px;
  z-index: 100;
  padding: 15px;
  border-radius: 10px;
  white-space: nowrap;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  user-select: none;
}

.fieldsSelect {
  padding: 0 10px;
  color: #666666;
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
}

.fieldsSelect.active {
  max-height: 100vh;
  transition: max-height 0.3s ease-in-out;
}

.fieldsSelect ul {
  list-style: none;
  padding: 0;
}

.fieldsSelect ul:first-child {
  padding-top: 10px;
}

.scroll-to-top {
  position: fixed;
  bottom: 0px;
  right: 20px;
  animation: fadeIn 700ms ease-in-out 1s both;
  cursor: pointer;
  font-size: 40px;
  padding: 4px;
}

.geoSearchGroup {
  margin: 5px;
  bottom : 20px;
}

#cesiumContainer {
  height: 70vh;
}


.csv__choice {
  position: absolute;
  right: 100px;
  top: 40px;
  display: flex;
  flex-direction: column;
  z-index: 100;
  box-sizing: border-box;
  width: 250px;
  background-color: white;
  border-radius: 15px;
  gap: 20px;
  padding: 0 15px;
  max-height: 0;
  transition: all 0.2s ease-out;
  overflow: auto;
}

.csv__choice.active {
  padding: 15px;
  max-height: 300px;
  transition: max-height 0.2s ease-in;
  border: 1px solid rgba(0, 0, 0, .15);
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
}

.csv__choice label {
  width: 50px;
}

.csv__choice button {
  width: 100px;
  align-self: end;
}

.loadingExport {
  width: 100%;
  height: 10px;
}

.loadingTrack {
  width: 0;
  height: 100%;
  border-radius: 10px;
  animation: loadingExport 1.5s infinite;
}

@keyframes loadingExport {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }

}
